// #010A35

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faAngleRight,
  faEnvelope,
  faMapMarkerAlt,
  faMarker,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div
      class="container-fluid  footer pt-3 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ backgroundColor: "rgba(0, 44, 87, 0.9)" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h1 class="text-white">
              <img class="img-fluid" src="https://eazysites.s3.amazonaws.com/eazydms/img/logo/eazy_dms_new_logo.png" alt="EAZY DMS" width="120" />
            </h1>
          </div>
          <div class="col-lg-5 col-6 d-flex justify-content-end align-items-center">
            {/* <h5 class="text-light mb-4">Follow Us</h5> */}
            <div class="d-flex">
              <Link
                class="btn btn-square rounded-circle me-1"
                to="https://twitter.com/eazyerp"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
              <Link
                class="btn btn-square rounded-circle me-1"
                to="https://www.facebook.com/eazyerp"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link
                class="btn btn-square rounded-circle me-1"
                to="https://www.youtube.com/channel/UCiAOxBYpm4VCZqhKQI3dZNw/videos"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={faYoutube} />
              </Link>
              <Link
                class="btn btn-square rounded-circle me-1"
                to="https://www.linkedin.com/company/singhal-system-solutions-pvt-ltd---offical-page?trk=tyah&trkInfo=tas%3Aeazy%20b%2Cidx%3A1-2-2"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </Link>
              <Link
                class="btn btn-square rounded-circle me-1"
                to="https://instagram.com/eazy_erp?igshid=YmMyMTA2M2Y="
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
            </div>
          </div>
          {/* <div className="col-1"></div> */}
        </div>
        <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, transparent, rgb(255, 255, 255), transparent)",
                    height: "1px",
                    marginTop: "5px"
                  }}
                ></div>
        <div class="row mt-4">

          
          <div class="col-lg-5 col-md-12 col-12">
            <h5 class="text-light mb-4">Get In Touch</h5>
            <p>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: "20px" }}
              />
              {/* <i class="fa fa-map-marker-alt me-3"></i> */}
              Eazy ERP Technologies Private Limited, <br />
              Head Office: 201, 2nd Floor, Vipul Agora, <br />
              MG Road, Gurgaon, Haryana-122002, India
            </p>

            <p class="mb-1">
              <FontAwesomeIcon icon={faPhoneSquare} />

              <a class="text_white mb-0" href="tel:+91 12 4479 4479">
                {" "}
                +91 12 4479 4479
              </a>
            </p>
            <p class="mb-1">
              <FontAwesomeIcon icon={faPhoneSquare} />
              <a class="text_white mb-0" href="tel:+91 813 088 5533">
                +91 813 088 5533
              </a>
            </p>
            <p class="mb-1">
              <FontAwesomeIcon icon={faPhoneSquare} />
              <a class="text_white mb-0" href="tel:+91 704 219 1995">
                +91 704 219 1995
              </a>
            </p>
            <p class="mb-1">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                class="text_white mb-0"
                href="mailto:contact@eazydms.com"
                target="_new"
              >
                {" "}
                contact@eazydms.com
              </a>
            </p>
          </div>
          <div className="d-lg-none d-md-block d-sm-block my-4"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, transparent, rgb(255, 255, 255), transparent)",
                    height: "1px",
                    
                  }}
                ></div>
          <div class="col-lg-4 col-md-6 col-6">
            <h5 class="text-light mb-4">Useful Links</h5>
            <Link
              class="btn btn-link"
              to="/"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Home
            </Link>
            <Link
              class="btn btn-link"
              to="/About"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              About EAZY{" "}
            </Link>
            <Link
              class="btn btn-link"
              to="/distributor-management-software"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Features
            </Link>
            <Link
              class="btn btn-link"
              to="/Customer"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Customers
            </Link>
            <Link
              class="btn btn-link"
              to="img/pdf/company-profile.pdf"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Company Profile
            </Link>
            <Link
              class="btn btn-link"
              to="img/pdf/eazydms.pdf"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Product Presentation
            </Link>
          </div>
          <div class="col-lg-3 col-md-6 col-6">
            <h5 class="text-light mb-4">Useful Links</h5>
            <Link
              class="btn btn-link"
              to="/Media"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />{" "}
              Media
            </Link>
            <Link
              class="btn btn-link"
              to="/Contact"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />{" "}
              Contact Us
            </Link>
            <Link
              class="btn btn-link"
              to="/Blogs"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Blog
            </Link>
            <Link
              class="btn btn-link"
              to="/privacy-policy"
              style={{ textDecoration: "none" }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  marginRight: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
              Privacy Policy
            </Link>
          </div>

        </div>
        <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, transparent, rgb(255, 255, 255), transparent)",
                    height: "1px",
                    marginTop: "20px"
                  }}
                ></div>
      </div>
      <div class="container copyright">
        <div class="container">
          <div class="row text-center">
            <div class="col-12 text-center mb-3 mb-md-0">
              &copy;{" "}
              <a href="#" style={{ textDecoration: "none" }}>
                Eazy DMS
              </a>
              , All Right Reserved.
            </div>
            <div class="col-md-6 text-center text-md-end"></div>
          </div>
        </div>
      </div>
      {/* <img src={bg} alt="circuit_img"  className="circuit_img"/> */}
    </div>
    // </div>
  );
};

export default Footer;
