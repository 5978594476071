import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobe,
  faMap,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export const ContactFooter = () => {
  return (
    <>
      <div class="break-p"></div>
      <div class="break-p"></div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h4>HEAD OFFICE</h4>
          <div class="contact-head">
            <div class="contact-icon">
              <div class="contact-inner">
                <div class="col-md-6">
                  <h4>Reach Us</h4>
                  <a
                    href="#"
                    style={{ display: "flex", textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        marginRight: "15px",
                        color: "#025282",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                    <p className="mb-2">
                      Eazy ERP Technologies Private Limited,
                      <br />
                      Head Office: 201, 2nd Floor, Vipul Agora, MG Road,
                      <br /> Gurgaon, Haryana-122002, India
                    </p>
                  </a>
                </div>
                <div class="col-md-6">
                  <h4>Contact Info</h4>
                 <div className="head_office_contact mb-2">
                 <a
                    href="tel:+91 12 4479 4479"
                    style={{ textDecoration: "none" }}
                  >
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <p>+91 12 4479 4479</p>
                  </a>
                  <a
                    href="tel:+91 704 219 1995"
                    style={{ textDecoration: "none" }}
                  >
                    <p>
                      +91 704 219 1995
                    </p>
                  </a>
                  <a
                    href="tel:+91 874 588 5500"
                    style={{ textDecoration: "none" }}
                  >
                    <p>+91 874 588 5500</p>
                  </a>
                 </div>
                  <a
                    href="mailto:contact@eazydms.com"
                    target="_new"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        marginRight: "15px",
                        color: "#025282",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <p>contact@eazydms.com</p>
                  </a>
                  <a
                    href="https://www.eazydms.com"
                    target="_new"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        marginRight: "15px",
                        color: "#025282",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faGlobe} />
                    </span>
                    <p>www.eazydms.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="break-p"></div>
      <div class="row">
        <h4 class="col-md-12">BRANCH OFFICES</h4>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="contact-head">
            <h4>Mumbai</h4>
            <div class="contact-icon">
              <div class="contact-inner">
                <a href="#" style={{ display: "flex", textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faMap} />
                  </span>
                  <p>
                    175, 2nd Floor, KAGALWALA, HOUSE, VIDHYANAGRI MARG, BEHIND
                    HONDA SHOWROOM, KALINA, SANTACRUZ EAST, <br /> Mumbai,
                    Maharashtra - 400098, India
                  </p>
                </a>

                <a href="#" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p>Pranil Pawar</p>
                </a>
                <a
                  href="tel:+91 874 588 2200"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <p>+91 874 388 7700</p>
                </a>
                <a
                  href="mailto:contact@eazydms.com"
                  target="_new"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <p>contact@eazydms.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="contact-head">
            <h4>Ahmedabad</h4>
            <div class="contact-icon">
              <div class="contact-inner">
                <a href="#" style={{ display: "flex", textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faMap} />
                  </span>
                  <p>
                    6th Floor, Shree Krishna Centre Near Mithakali Six Road,
                    Navrangpura
                    <br />
                    Ahmedabad – 380009, India
                  </p>
                </a>
                <a href="#" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p>Namrita Pathak</p>
                </a>
                <a
                  href="tel:+91 931 000 5635"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <p>+91 931 000 5635</p>
                </a>
                <a
                  href="mailto:contact@eazydms.com"
                  target="_new"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <p>contact@eazydms.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="break-p"></div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="contact-head">
            <h4>Bangalore</h4>
            <div class="contact-icon">
              <div class="contact-inner">
                <a href="#" style={{ display: "flex", textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faMap} />
                  </span>
                  <p>
                    1st Floor ,Gopala Krishna Complex #453, Residency Road
                    <br />
                    Bengaluru-560025, India
                  </p>
                </a>
                <a href="#" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p> Praveena Pakala</p>
                </a>
                
                <a
                  href="tel:+91 874 388 3300"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPhone} />
                  </span>{" "}
                  <p>+91 874 388 3300</p>
                </a>
                <a
                  href="mailto:contact@eazydms.com"
                  target="_new"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <p>contact@eazydms.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="contact-head">
            <h4>International</h4>
            <div class="contact-icon">
              <div class="contact-inner">
                <a href="#" style={{ display: "flex", textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faMap} />
                  </span>
                  <p>
                    Flat 101, B11, 1004 Estate, Victoria Island,
                    <br />
                    Lagos, Nigeria
                  </p>
                </a>
                <a href="#" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p>Chandan Yadav</p>
                </a>
                <a
                  href="tel:+234 907 585 1441"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <p>+234 907 585 1441</p>
                </a>
                <a
                  href="mailto:contact@eazydms.com"
                  target="_new"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      marginRight: "15px",
                      color: "#025282",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <p>contact@eazydms.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
